<template>
    <l-map
        v-if="center.lat != null"
        ref="map"
        :zoom="getZoomLevel"
        :max-zoom="20"
        :center="center"
        style="height: 100%; width: 100%"
        :options="options"
    >
        <l-control-layers
            v-if="layersControlVisible"
            :collapsed="false"
            :sort-layers="true"
        />
        <l-tile-layer
            :visible="aerialTileVisible"
            :url="aerialTileUrl"
            :options="tile_options"
            name="Aerial view"
            layer-type="base"
        ></l-tile-layer>
        <l-tile-layer
            :visible="cadastralTileVisible"
            :url="cadastralTileUrl"
            :options="{ maxZoom: 21, maxNativeZoom: 19 }"
            name="Cadastral view"
            layer-type="base"
        ></l-tile-layer>
        <l-geo-json
            v-if="flood_regular_geojson != null"
            :visible="floodVisible"
            :geojson="flood_regular_geojson"
            :options-style="style_flood"
            name="Regular flood zones"
            layer-type="overlay"
        ></l-geo-json>
        <l-geo-json
            v-if="flood_insurance_exclusion_geojson != null"
            :visible="floodVisible"
            :geojson="flood_insurance_exclusion_geojson"
            :options-style="style_flood"
            name="Insurance exclusion flood zones"
            layer-type="overlay"
        ></l-geo-json>
        <l-geo-json
            v-if="map_data_ready"
            ref="parcel_features"
            :geojson="parcel_geojson"
            :options="parcel_options"
        ></l-geo-json>
        <l-geo-json
            v-if="map_data_ready"
            ref="building_features"
            :geojson="building_geojson"
            :options="building_options"
        ></l-geo-json>
        <l-geo-json
            v-if="map_data_ready && size !== 'small'"
            :geojson="address_geojson"
            :options="address_options"
        ></l-geo-json>
        <l-geo-json
            v-if="swimming_pools_geojson != null"
            :geojson="swimming_pools_geojson"
            :options-style="style_swimming_pools"
            name="Swimming pools"
            layer-type="overlay"
        ></l-geo-json>
        <l-tile-layer
            v-if="showStreetNames"
            :url="streetnameTileUrl"
            :options="{ maxZoom: 21, maxNativeZoom: 21 }"
            layer-type="overlay"
            name="Street name"
        ></l-tile-layer>
        <slot name="custom-layer"></slot>
    </l-map>
</template>

<script>
import { circleMarker } from 'leaflet'
import { LControlLayers, LMap, LTileLayer, LGeoJson } from 'vue2-leaflet'
import 'leaflet/dist/leaflet.css'
import axios from '@/shared/plugins/axios'
import utils from '@/shared/plugins/utils'

export default {
    components: {
        LControlLayers,
        LMap,
        LTileLayer,
        LGeoJson,
    },
    props: {
        buildingId: {
            type: String,
            required: true,
        },
        lang: {
            type: String,
            default: 'fr',
            validator(value) {
                return ['en', 'fr', 'nl', 'en-BE', 'fr-BE', 'en-BE'].includes(value)
            },
        },
        level: {
            type: String,
            required: true,
            validator(value) {
                return ['0', '0.1', '0.2', '0.5', '0.7', '1', '2', '3'].includes(value)
            },
        },
        mapScene2d: {
            type: Object,
            default: function() {
                return {
                    building_geojson: null,
                    parcel_geojson: null,
                    address_geojson: null,
                    swimming_pool_geojson: null,
                    flood_geojson: null,
                }
            },
        },
        parcelIds: {
            type: Array,
            default: function() {
                return []
            },
        },
        autoSelectParcels: {
            type: Boolean,
            default: true,
        },
        center: {
            type: Object,
            default: function() {
                return {
                    lng: null,
                    lat: null,
                }
            },
        },
        readOnly: {
            type: Boolean,
            default: false,
        },
        focusLayer: {
            type: String,
            default: 'building',
            validator(value) {
                return ['building', 'parcels', 'selected', 'flood'].includes(value)
            },
        },
        size: {
            type: String,
            default: 'full-page',
            validator(value) {
                return ['full-page', 'medium', 'fit', 'small'].includes(value)
            },
        },
        options: {
            type: Object,
            default: function() {
                return {}
            },
        },
        layersControlVisible: {
            type: Boolean,
            default: true,
        },
        aerialTileVisible: {
            type: Boolean,
            default: true,
        },
        cadastralTileUrl: {
            type: String,
            default: 'https://tile.openstreetmap.org/{z}/{x}/{y}.png',
        },
        cadastralTileVisible: {
            type: Boolean,
            default: false,
        },
        floodVisible: {
            type: Boolean,
            default: true,
        },
    },
    data: function() {
        return {
            showStreetNames: true,
        }
    },
    computed: {
        aerialTileUrl() {
            if (this.region !== 'Wallonia') {
                return utils.urlJoin(
                    process.env.VUE_APP_API_URL,
                    '/tiles/aerial_vlg/{z}/{x}/{y}.jpg'
                )
            } else {
                return utils.urlJoin(
                    process.env.VUE_APP_API_URL,
                    '/tiles/aerial_wal/{z}/{x}/{y}.jpg'
                )
            }
        },
        getZoomLevel() {
            let zoom = this.level < '0.5' ? 18 : 20
            if (this.size == 'medium') zoom -= 1
            if (this.size == 'small') zoom -= 3
            if (this.focusLayer === 'flood') zoom -= 2

            return zoom
        },
        building_geojson() {
            let building_geojson = { ...this.mapScene2d.building_geojson }
            if (this.focusLayer == 'selected') {
                building_geojson.features = building_geojson.features.filter(
                    (feature) => feature.properties.building_id == this.buildingId
                )
            }
            return building_geojson
        },
        parcel_geojson() {
            let parcel_geojson = { ...this.mapScene2d.parcel_geojson }
            if (this.focusLayer == 'selected') {
                parcel_geojson.features = parcel_geojson.features.filter((feature) =>
                    this.parcelIds.includes(feature.properties.parcel_id)
                )
            }
            return parcel_geojson
        },
        address_geojson() {
            if (this.focusLayer == 'selected') {
                return {}
            }
            return this.mapScene2d.address_geojson
        },
        swimming_pools_geojson() {
            if (this.focusLayer == 'selected') {
                return {}
            }
            return this.mapScene2d.swimming_pools_geojson
        },
        flood_regular_geojson() {
            let geojson = JSON.parse(JSON.stringify(this.mapScene2d.flood_geojson))
            if (geojson) {
                geojson.features = geojson.features.filter((f) => {
                    return f.properties.source === 'regular'
                })
            }
            return geojson
        },
        flood_insurance_exclusion_geojson() {
            let geojson = JSON.parse(JSON.stringify(this.mapScene2d.flood_geojson))
            if (geojson) {
                geojson.features = geojson.features.filter((f) => {
                    return f.properties.source === 'insurance_exclusion'
                })
            }
            return geojson
        },
        map_data_ready() {
            return (
                this.mapScene2d.building_geojson !== null &&
                this.mapScene2d.parcel_geojson !== null &&
                this.mapScene2d.address_geojson !== null
            )
        },
        parcel_options() {
            return {
                interactive: this.focusLayer === 'parcels' && !this.readOnly,
                onEachFeature: (feature, layer) => {
                    layer.on({
                        mouseover: (e) => {
                            if (!this.readOnly) {
                                e.target.feature.properties.hover = true
                                this.$refs.parcel_features.mapObject.resetStyle(e.target)
                            }
                        },
                        mouseout: (e) => {
                            e.target.feature.properties.hover = false
                            this.$refs.parcel_features.mapObject.resetStyle(e.target)
                        },
                        click: (e) => {
                            if (!this.readOnly) {
                                this.toggle_parcel(e.target.feature.properties.parcel_id)
                            }
                        },
                    })
                },
                style: (feature) => {
                    var style = {
                        color: '#fff',
                        fillColor: '#fff',
                        weight: 1,
                        opacity: this.focusLayer === 'parcels' ? 1 : 0.4,
                        fillOpacity: this.focusLayer === 'parcels' ? 0.2 : 0,
                    }
                    if (feature.properties.hover === true) {
                        style.fillColor = '#8cf'
                        // style.color = '#0096ff'
                        style.fillOpacity = 1
                    }
                    if (this.selected_parcel(feature.properties.parcel_id)) {
                        style.weight = 3
                        style.fillOpacity = 0.8
                    }
                    if (this.focusLayer == 'selected') {
                        return {
                            color: '#0096ff',
                            fillOpacity: 0,
                            opacity: 1,
                            weight: 3,
                        }
                    }
                    return style
                },
            }
        },
        building_options() {
            return {
                interactive: this.focusLayer === 'building' && !this.readOnly,
                onEachFeature: (feature, layer) => {
                    layer.on({
                        mouseover: (e) => {
                            if (!this.readOnly) {
                                e.target.feature.properties.hover = true
                                this.$refs.building_features.mapObject.resetStyle(
                                    e.target
                                )
                                //if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) {
                                e.target.bringToFront()
                                //}
                            }
                        },
                        mouseout: (e) => {
                            e.target.feature.properties.hover = false
                            this.$refs.building_features.mapObject.resetStyle(e.target)
                        },
                        click: (e) => {
                            if (this.readOnly) {
                                return
                            } else if (this.focusLayer == 'building') {
                                this.$emit(
                                    'update:buildingId',
                                    e.target.feature.properties.building_id
                                )
                                this.$emit(
                                    'update:address',
                                    e.target.feature.properties.building_id
                                )
                                /*this.$emit(
                                    'update:parcelIds',
                                    e.target.feature.properties.parcel_ids
                                )*/
                            } else if (this.focusLayer == 'secondary-buildings') {
                                /*
                                this.TOGGLE_SECONDARY_BUILDING_ID(
                                    e.target.feature.properties.building_id
                                )*/
                            } else {
                                return
                            }
                        },
                    })
                },
                style: (feature) => {
                    var style = {
                        color: '#ff6f00', // '#357',
                        fillColor: '#ffaf80', //: '#e0e8ff'
                        weight: this.focusLayer === 'building' ? 1 : 0,
                        opacity: 1,
                        fillOpacity: 0.8,
                    }
                    if (feature.properties.hover === true) {
                        style.fillColor = '#fc9'
                        style.color = '#753'
                        style.fillOpacity = 1
                    }
                    if (this.main(feature.properties.building_id)) {
                        style.fillColor = '#ff6f00'
                        if (this.focusLayer === 'building') {
                            style.fillColor = '#ff6f00'
                            style.color = '#fff'
                            if (this.size !== 'small') style.weight = 3
                        } else if (this.focusLayer === 'selected') {
                            style.weight = 0
                            style.fillOpacity = 1
                        } else {
                            style.opacity = 0.6
                            style.weight = 0
                        }
                    } else if (this.secondary(feature.properties.building_id)) {
                        style.fillColor = '#99f'
                        if (this.focusLayer === 'building') {
                            style.fillColor = '#99f'
                            style.color = '#337'
                            style.weight = 3
                        } else {
                            style.opacity = 0.6
                            style.weight = 0
                        }
                    }
                    return style
                },
            }
        },
        address_options() {
            return {
                interactive: false,
                style: {
                    weight: 0,
                    opacity: 0,
                    fillOpacity: 0,
                },
                pointToLayer: function(feature, latlng) {
                    let label = String(feature.properties.streetnumber)
                    return new circleMarker(latlng, {
                        radius: 0,
                        interactive: false,
                    })
                        .bindTooltip(label, {
                            permanent: true,
                            opacity: 1,
                            direction: 'center',
                            className: 'address-labels',
                        })
                        .openTooltip()
                },
            }
        },
        tile_options() {
            return { maxZoom: 20, maxNativeZoom: 18 }
        },
        streetnameTileUrl() {
            let mapping = { 'fr-BE': 'fr', 'nl-BE': 'nl' }
            let normalized_lang = mapping[this.lang]
            normalized_lang = normalized_lang ? normalized_lang : 'fr'
            return utils.urlJoin(
                process.env.VUE_APP_API_URL,
                `/tiles/streets/streets_${normalized_lang}/{z}/{x}/{y}.png`
            )
        },
        style_swimming_pools() {
            return {
                color: '#ff6f00', // '#357',
                fillColor: '#9cf', //: '#e0e8ff'
                weight: this.focusLayer === 'building' ? 1 : 0,
                opacity: 1,
                fillOpacity: 0.6,
            }
        },

        region() {
            return this.buildingId.includes('picc_') ? 'Wallonia' : 'Other'
        },
    },
    watch: {
        buildingId: {
            immediate: true,
            handler(buildingId, oldBuildingId) {
                if (this.map_data_ready) {
                    if (typeof this.$refs.building_features !== 'undefined') {
                        this.$refs.building_features.mapObject.eachLayer((layer) => {
                            this.$refs.building_features.mapObject.resetStyle(layer)
                        })
                    }
                    if (!this.readOnly) {
                        this.auto_select_parcels()
                    }
                    this.$nextTick(() => {
                        this.fetch_map_data()
                    })
                } else {
                    this.fetch_map_data().then(() => {
                        if (!this.readOnly) {
                            this.auto_select_parcels()
                        }
                    })
                }
            },
        },
        parcelIds() {
            if (
                this.map_data_ready &&
                typeof this.$refs.parcel_features !== 'undefined'
            ) {
                this.$refs.parcel_features.mapObject.eachLayer((layer) => {
                    this.$refs.parcel_features.mapObject.resetStyle(layer)
                })
            }
        },
        lang: {
            handler(land, oldLang) {
                this.showStreetNames = false
                this.$nextTick(() => {
                    this.showStreetNames = true
                })
            },
        },
    },
    methods: {
        main(building_id) {
            return this.buildingId === building_id
        },
        secondary(building_id) {
            return (
                /* TODO: find secondary buildings based on selected parcels
                this.mapScene2d.parcel_geojson.features.find()
                .state.Address.data.features.secondary_building_ids.indexOf(
                    building_id
                ) != -1*/
                false
            )
        },
        selected_parcel(parcel_id) {
            return this.parcelIds.indexOf(parcel_id) != -1
        },
        // new
        fetch_map_data() {
            return axios
                .get('map/scene2d', {
                    params: {
                        building_id: this.buildingId,
                    },
                })
                .then((response) => {
                    this.$emit('update:mapScene2d', response.data)
                })
        },
        toggle_parcel(parcel_id) {
            let index = this.parcelIds.indexOf(parcel_id)
            let parcel_ids = [...this.parcelIds]
            if (index === -1) {
                parcel_ids.push(parcel_id)
                //state.data.features.parcels.push(parcel)
            } else {
                parcel_ids.splice(index, 1)
                //state.data.features.parcels.splice(index, 1)
            }
            this.$emit('update:parcelIds', parcel_ids)
        },
        auto_select_parcels() {
            for (let i = 0; i < this.mapScene2d.building_geojson.features.length; i++) {
                if (
                    this.mapScene2d.building_geojson.features[i].properties.building_id ==
                    this.buildingId
                ) {
                    let parcel_ids = this.mapScene2d.building_geojson.features[i]
                        .properties.parcel_ids
                    this.$emit('update:parcelIds', parcel_ids)
                }
            }
            return new Promise((res, rej) => {
                res()
            })
        },
        style_flood(feature) {
            let opacity = 0
            switch (feature.properties.type.toLowerCase()) {
                case 'low':
                    opacity = 0.2
                    break
                case 'medium':
                    opacity = 0.4
                    break
                case 'high':
                default:
                    opacity = 0.6
            }
            return {
                fillColor:
                    feature.properties.source === 'regular' ? '#0048a0' : '#11ed8a',
                weight: 0,
                fillOpacity: opacity,
            }
        },
    },
}
</script>

<style lang="scss">
.b-tooltips {
    .b-tooltip:not(:last-child) {
        margin-right: 0.5em;
    }
    .b-tooltip {
        margin-bottom: 0.5em;
    }
}
.leaflet-tooltip.address-labels {
    background-color: transparent;
    border: transparent;
    box-shadow: none;
}
</style>
